import habitat from 'preact-habitat';
import './styles.scss';
import Widget from './routes/index';

const inputHash = window.location.hash;
const hash = inputHash.slice(inputHash.indexOf('/'));

location.href = `#/splash${hash}`;

const _habitat = habitat(Widget);

_habitat.render({
  selector: '[data-widget-host="client-app-widget"]',
  clean: true,
});
